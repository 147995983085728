import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "mt-5 row" }
const _hoisted_3 = { class: "col-lg-4 col-md-6 col-sm-8 mx-auto" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "input-group mb-2" }
const _hoisted_8 = {
  key: 0,
  class: "input-group mb-2"
}
const _hoisted_9 = { class: "input-group-text" }
const _hoisted_10 = { class: "input-group mb-2" }
const _hoisted_11 = { class: "input-group-text" }
const _hoisted_12 = ["type"]
const _hoisted_13 = ["title"]
const _hoisted_14 = {
  key: 1,
  class: "input-group mb-2"
}
const _hoisted_15 = { class: "input-group-text" }
const _hoisted_16 = ["type"]
const _hoisted_17 = ["title"]
const _hoisted_18 = {
  key: 4,
  class: "p-2 text-danger"
}
const _hoisted_19 = {
  key: 5,
  class: "mt-4 p-2 alert alert-warning"
}
const _hoisted_20 = {
  key: 6,
  class: "p-2"
}
const _hoisted_21 = {
  key: 7,
  class: "p-2"
}

import { onMounted, ref, watch } from "vue";
import router from "../router";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faLock, faGamepad, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import SignService from "../common/sign.service";

import { useSignStore } from "../store/sign";
import {ApiError} from "@/modules/game/type";



export default /*@__PURE__*/_defineComponent({
  __name: 'SignView',
  setup(__props) {


const signStore = useSignStore();

const isRegister = ref(false);
const error = ref<string|null>(null);
const showPassword = ref(false);
const showGamePassword = ref(false);

const email = ref("");
const nickname = ref("");
const password = ref("");
const gamePassword = ref("");

function doLogin() {
	if (email.value === "" || password.value === "") {
		error.value = "Vyplň všechny pole";
	} else {
		error.value = "";
		SignService.signIn(email.value, password.value);
	}
}

function doRegister() {
	if (email.value === "" || nickname.value === "" || password.value === "") {
		error.value = "Vyplň všechny pole";
		return;
	}

	if (password.value.length < 6) {
		error.value = "Heslo musí mít alespoň 6 znaků";
		return;
	}

	if (gamePassword.value.length < 6) {
		error.value = "Heslo od admina musí mít alespoň 6 znaků";
		return;
	}

	if (nickname.value.length < 3) {
		error.value = "Přezdívka musí mít alespoň 3 znaky";
		return;
	}

	// validate email
	if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
		error.value = "Email není ve správném formátu";
		return;
	}

	error.value = null;
	SignService.signUp(email.value, nickname.value, password.value, gamePassword.value);
}

watch(() => signStore.error, (apiError: ApiError) => {
	if (apiError) {
		error.value = apiError.code !== 500 ? apiError.message : "Něco se pokazilo";
	} else {
		error.value = null;
	}
});

watch(() => signStore.authenticated, (newVal) => {
	if (newVal) {
		router.push({name: "home"});
	}
});

onMounted(() => {
	// redirect if already authenticated
	if (signStore.authenticated) {
		router.push({name: "home"});
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["card p-2", { error: error.value !== '' }])
        }, [
          (isRegister.value)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, "Registrace"))
            : (_openBlock(), _createElementBlock("h1", _hoisted_5, "Přihlášení")),
          _createElementVNode("form", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "input-group-text" }, "@", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                type: "email",
                class: "form-control",
                name: "email",
                placeholder: "Email",
                required: ""
              }, null, 512), [
                [_vModelText, email.value]
              ])
            ]),
            (isRegister.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_unref(FontAwesomeIcon), { icon: _unref(faUser) }, null, 8, ["icon"])
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((nickname).value = $event)),
                    type: "text",
                    minlength: "3",
                    class: "form-control",
                    placeholder: "Přezdívka",
                    required: ""
                  }, null, 512), [
                    [_vModelText, nickname.value]
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_unref(FontAwesomeIcon), { icon: _unref(faLock) }, null, 8, ["icon"])
              ]),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((password).value = $event)),
                type: showPassword.value ? 'text' : 'password',
                minlength: "6",
                class: "form-control",
                name: "password",
                placeholder: "Heslo",
                required: ""
              }, null, 8, _hoisted_12), [
                [_vModelDynamic, password.value]
              ]),
              _createElementVNode("span", {
                class: "input-group-text",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (showPassword.value = !showPassword.value)),
                title: showPassword.value ? 'Skrýt heslo' : 'Zobrazit heslo'
              }, [
                _createVNode(_unref(FontAwesomeIcon), {
                  icon: showPassword.value ? _unref(faEyeSlash) : _unref(faEye)
                }, null, 8, ["icon"])
              ], 8, _hoisted_13)
            ]),
            (isRegister.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, [
                    _createVNode(_unref(FontAwesomeIcon), { icon: _unref(faGamepad) }, null, 8, ["icon"])
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((gamePassword).value = $event)),
                    type: showGamePassword.value ? 'text' : 'password',
                    minlength: "6",
                    class: "form-control",
                    name: "gamePassword",
                    placeholder: "Heslo od admina",
                    required: ""
                  }, null, 8, _hoisted_16), [
                    [_vModelDynamic, gamePassword.value]
                  ]),
                  _createElementVNode("span", {
                    class: "input-group-text",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (showGamePassword.value = !showGamePassword.value)),
                    title: showGamePassword.value ? 'Skrýt heslo' : 'Zobrazit heslo'
                  }, [
                    _createVNode(_unref(FontAwesomeIcon), {
                      icon: showGamePassword.value ? _unref(faEyeSlash) : _unref(faEye)
                    }, null, 8, ["icon"])
                  ], 8, _hoisted_17)
                ]))
              : _createCommentVNode("", true),
            (isRegister.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  type: "submit",
                  class: "btn btn-primary",
                  onClick: _withModifiers(doRegister, ["prevent"])
                }, "Vytvořit účet"))
              : (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  type: "submit",
                  class: "btn btn-primary",
                  onClick: _withModifiers(doLogin, ["prevent"])
                }, "Přihlásit se")),
            (error.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(error.value), 1))
              : _createCommentVNode("", true),
            (isRegister.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_19, _cache[9] || (_cache[9] = [
                  _createTextVNode(" Přístup do hry je zatím "),
                  _createElementVNode("strong", null, "jen na pozvání", -1),
                  _createTextVNode(", chceš hrát? Napiš na "),
                  _createElementVNode("a", { href: "mailto:chcihrat@kokume.eu" }, "chcihrat@kokume.eu", -1),
                  _createTextVNode(" z e-mailu, se kterým se budeš registrovat. ")
                ])))
              : _createCommentVNode("", true),
            (isRegister.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_20, [
                  _cache[10] || (_cache[10] = _createTextVNode("Už máš svůj učet? ")),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => {isRegister.value = !isRegister.value; error.value = ''})
                  }, "Tady se přihlaš")
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_21, [
                  _cache[11] || (_cache[11] = _createTextVNode("Ještě tu nemáš účet? ")),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => {isRegister.value = !isRegister.value; error.value = ''})
                  }, "Vytvoř si ho tady")
                ]))
          ])
        ], 2)
      ])
    ])
  ]))
}
}

})