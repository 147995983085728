import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal-title fs-5",
  id: 'map_info-label'
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "list-unstyled" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "list-unstyled" }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { class: "list-unstyled" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = {
  key: 3,
  class: "pt-3"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  id: "hire_hero",
  class: "collapse fade"
}
const _hoisted_23 = { key: 2 }
const _hoisted_24 = {
  class: "btn btn-link p-0",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#upgrade_building"
}
const _hoisted_25 = {
  id: "upgrade_building",
  class: "collapse fade"
}
const _hoisted_26 = { class: "list-unstyled" }
const _hoisted_27 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_28 = {
  key: 3,
  class: "mt-1 alert alert-danger"
}
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = {
  key: 4,
  class: "mt-1 alert alert-danger"
}
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }

import Modal from "./Modal.vue";

import {computed, ref, watch} from "vue";

import {useMapStore} from "../store/map";
import {useRegionStore} from "../store/region";
import {useResourceStore} from "../store/resource";
import {useBuildingStore} from "../store/building";
import {usePlayerStore} from "../store/player";
import {useInventoryStore} from "../store/inventory";

import {Building, BuildingState, Hero, Item, MapFeature} from "../type";

import Timer from "../components/Timer.vue";
import Loading from "../components/Loading.vue";
import {LatLng} from "leaflet";
import {useHeroStore} from "@/modules/game/store/hero";


export default /*@__PURE__*/_defineComponent({
  __name: 'MapInfoModal',
  setup(__props) {

const mapStore = useMapStore();
const regionStore = useRegionStore();
const resourceStore = useResourceStore();
const buildingStore = useBuildingStore();
const playerStore = usePlayerStore();
const heroStore = useHeroStore();
const inventoryStore = useInventoryStore();

const show = ref<boolean>(false);

const dataType = ref<string|null>(null);
const data = ref<MapFeature|null>(null);

const title = computed(() => {
	if (!mapStore.selectedFeature) {
		return '';
	}

	let title = mapStore.selectedFeature.properties?.name || '';

	if (dataType.value === 'region') {
		title = 'Region: ' + title;
	}
	else if (dataType.value === 'resource') {
		if (!title) {
			title = data.value.properties.resource;
		}
		title = 'Zdroj: ' + title;
	}
	else if (dataType.value === 'building') {
		title = 'Budova: ' + title;
	}
	else if (dataType.value === 'item') {
		title = 'Předmět: ' + title;
	}

	return title;
});

const position = computed<LatLng>(() => {
	if (mapStore.selectedFeature && mapStore.selectedFeature.geometry.type === 'Point') {
		return new LatLng(
			mapStore.selectedFeature.geometry.coordinates[1],
			mapStore.selectedFeature.geometry.coordinates[0],
		);
	}

	return null;
});

const isInRange = computed(() => {
	if (!position.value || !playerStore.position) {
		return false;
	}

	return position.value.distanceTo(playerStore.position.latLng) <= playerStore.player.range;
});

// ----- Items ---------------------------------------------------------------------------------------------------------

function canPickUpItem() {
	return isInRange && !!playerStore.player?.hero;
}

function pickUpItem() {
	inventoryStore.pickUp(data.value.properties.uuid)
		.then(() => {
			show.value = false;
			mapStore.fetchData();
		});
}


// ----- Buildings -----------------------------------------------------------------------------------------------------

function upgradeBuilding() {
	if (!buildingStore.currentBuilding || !buildingStore.canUpgradeCurrentBuilding) {
		return;
	}

	if (buildingStore.currentBuilding.state !== BuildingState.BUILT) {
		return;
	}

	if (buildingStore.currentBuilding.level >= buildingStore.currentBuilding.type.max_level) {
		return;
	}

	buildingStore.upgradeBuilding(buildingStore.currentBuilding)
		.then((upgradedBuilding: Building) => {
			buildingStore.currentBuilding = upgradedBuilding;
			playerStore.fetchPlayer();
		});
}

function pickUpResources() {
	if (!buildingStore.currentBuilding) {
		return;
	}

	buildingStore.pickUpResources(buildingStore.currentBuilding)
		.then((updatedBuilding: Building) => {
			buildingStore.currentBuilding = updatedBuilding;
			playerStore.fetchPlayer();
		});
}

// ----- Regions -------------------------------------------------------------------------------------------------------

// ----- Heroes --------------------------------------------------------------------------------------------------------

const hireHeroData = ref<{name: string}>({name: ''});

function hireHero(hireHeroData: {name: string}) {
	if (buildingStore.currentBuilding?.type.slug !== 'base' || !isInRange) {
		return;
	}

	heroStore.hire(hireHeroData)
		.then((hero: Hero) => {
			playerStore.player.hero = hero;
		});
}

// ----- General -------------------------------------------------------------------------------------------------------

const clear = () => {
	show.value = false;
	data.value = null;
	mapStore.selectedFeature = null;
}

watch(() => mapStore.selectedFeature, (newVal: MapFeature|null) => {
	if (newVal) {
		dataType.value = newVal.properties.type;
		data.value = newVal;

		if (dataType.value === 'region') {
			regionStore.fetchRegion(newVal.properties.id);
		}
		else if (dataType.value === 'resource') {
			resourceStore.fetchData(newVal.properties.id);
		}
		else if (dataType.value === 'building') {
			buildingStore.changeCurrentBuilding(newVal.properties.uuid);
		}
		else if (dataType.value === 'item') {

		}

		show.value = true;
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    id: "map_info",
    show: show.value,
    onHidden: clear
  }, {
    header: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _createTextVNode(_toDisplayString(title.value) + " ", 1),
        _createElementVNode("small", {
          class: _normalizeClass(isInRange.value ? 'text-success' : 'text-black-50')
        }, "(" + _toDisplayString(isInRange.value ? 'v dosahu' : 'mimo dosah') + ")", 3)
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("button", {
        "aria-label": "Close",
        class: "btn-close",
        "data-bs-dismiss": "modal",
        type: "button"
      }, null, -1))
    ]),
    body: _withCtx(() => [
      (_unref(regionStore).regionLoading || _unref(resourceStore).loading || _unref(buildingStore).currentBuildingLoading)
        ? (_openBlock(), _createBlock(Loading, { key: 0 }))
        : _createCommentVNode("", true),
      (dataType.value === 'region' && _unref(regionStore).region)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_unref(regionStore).region.owner_id)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Tady kraluje: " + _toDisplayString(_unref(regionStore).region.owner_name) + " #" + _toDisplayString(_unref(regionStore).region.owner_id), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_4, "Volné území")),
            (_unref(regionStore).region.resource_amounts)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[6] || (_cache[6] = _createElementVNode("h6", null, "Zdroje na území", -1)),
                  _createElementVNode("ul", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(regionStore).region.resource_amounts, (production) => {
                      return (_openBlock(), _createElementBlock("li", null, _toDisplayString(production.type.name) + ": " + _toDisplayString(production.amount), 1))
                    }), 256))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (dataType.value === 'resource' && _unref(resourceStore).resource)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_unref(resourceStore).resource.resource_amounts)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createElementVNode("h6", null, "Zásoba", -1)),
                  _createElementVNode("ul", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resourceStore).resource.resource_amounts, (production) => {
                      return (_openBlock(), _createElementBlock("li", null, _toDisplayString(production.type.name) + ": " + _toDisplayString(production.amount), 1))
                    }), 256))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (dataType.value === 'item' && data.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", null, _toDisplayString(data.value.properties.description), 1),
            canPickUpItem
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-sm btn-primary",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (pickUpItem()), ["prevent"]))
                }, [
                  _cache[8] || (_cache[8] = _createTextVNode(" Vyzvednout ")),
                  (_unref(inventoryStore).pickUpLoading)
                    ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (dataType.value === 'building' && _unref(buildingStore).currentBuilding)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("ul", _hoisted_12, [
              _createElementVNode("li", null, " typ: " + _toDisplayString(_unref(buildingStore).currentBuilding.type.name), 1),
              (_unref(buildingStore).currentBuilding.owner.id !== _unref(playerStore).player.id)
                ? (_openBlock(), _createElementBlock("li", _hoisted_13, " vlastník: " + _toDisplayString(_unref(buildingStore).currentBuilding.owner.nickname), 1))
                : _createCommentVNode("", true),
              (_unref(buildingStore).currentBuilding.state)
                ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                    _createTextVNode(" stav: " + _toDisplayString(_unref(buildingStore).currentBuilding.state) + " ", 1),
                    (_unref(buildingStore).currentBuilding.state_valid_to)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                          _cache[9] || (_cache[9] = _createTextVNode(" (")),
                          _createVNode(Timer, {
                            "end-date": new Date((_unref(buildingStore)).currentBuilding.state_valid_to)
                          }, null, 8, ["end-date"]),
                          _cache[10] || (_cache[10] = _createTextVNode(") "))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_unref(buildingStore).currentBuilding.state === _unref(BuildingState).BUILT && _unref(buildingStore).currentBuilding.stock)
                ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                    _cache[11] || (_cache[11] = _createTextVNode(" sklad: ")),
                    (_unref(buildingStore).currentBuilding?.stock?.length > 0)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).currentBuilding.stock, (stock) => {
                            return (_openBlock(), _createElementBlock("li", null, _toDisplayString(stock.type.name) + ": " + _toDisplayString(stock.amount), 1))
                          }), 256))
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_18, " prázdný"))
                  ]))
                : _createCommentVNode("", true),
              (_unref(buildingStore).currentBuilding.state === _unref(BuildingState).BUILT)
                ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                    (_unref(buildingStore).currentBuilding?.stock?.length > 0 && isInRange.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("button", {
                            class: "btn btn-sm btn-primary",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (pickUpResources()), ["prevent"]))
                          }, [
                            _cache[12] || (_cache[12] = _createTextVNode(" Vyzvednout suroviny ")),
                            (_unref(buildingStore).pickUpLoading)
                              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (!_unref(playerStore)?.player?.hero && _unref(buildingStore).currentBuilding.type.slug === 'base' && isInRange.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _cache[15] || (_cache[15] = _createElementVNode("button", {
                            class: "btn btn-sm btn-secondary",
                            "data-bs-toggle": "collapse",
                            "data-bs-target": "#hire_hero"
                          }, " Najmout hrdinu ", -1)),
                          _createElementVNode("div", _hoisted_22, [
                            _cache[14] || (_cache[14] = _createElementVNode("label", {
                              for: "hero_name",
                              class: "form-label"
                            }, "Jméno hrdiny", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "form-control",
                              id: "hero_name",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((hireHeroData.value.name) = $event))
                            }, null, 512), [
                              [_vModelText, hireHeroData.value.name]
                            ]),
                            _createElementVNode("button", {
                              class: "btn btn-sm btn-success mt-2",
                              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (hireHero(hireHeroData.value)), ["prevent"]))
                            }, [
                              _cache[13] || (_cache[13] = _createTextVNode(" Najmout ")),
                              (_unref(heroStore).hireLoading)
                                ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(buildingStore).currentBuilding.state === _unref(BuildingState).BUILT && _unref(buildingStore).currentBuilding.level < _unref(buildingStore).currentBuilding.type.max_level)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createElementVNode("button", _hoisted_24, " Upgrade na " + _toDisplayString(_unref(buildingStore).currentBuilding?.level + 1) + " úroveň ", 1),
                          _createElementVNode("div", _hoisted_25, [
                            _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Náklady:", -1)),
                            _createElementVNode("ul", _hoisted_26, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).currentBuilding.upgrade_cost, (cost) => {
                                return (_openBlock(), _createElementBlock("li", null, [
                                  _createTextVNode(_toDisplayString(cost.type.name) + ": " + _toDisplayString(cost.amount) + " ", 1),
                                  (_unref(playerStore).findResource(cost.type.slug).amount < cost.amount)
                                    ? (_openBlock(), _createElementBlock("strong", _hoisted_27, " (chybí " + _toDisplayString(cost.amount - _unref(playerStore).findResource(cost.type.slug).amount) + " !!) ", 1))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 256))
                            ]),
                            (_unref(buildingStore).canUpgradeCurrentBuilding && isInRange.value)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: "btn btn-sm btn-success",
                                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (upgradeBuilding()), ["prevent"]))
                                }, [
                                  _createTextVNode(" Upgrade na úroveň " + _toDisplayString(_unref(buildingStore).currentBuilding.level + 1) + " ", 1),
                                  (_unref(buildingStore).upgradeLoading)
                                    ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(buildingStore).upgradeError)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          (_unref(buildingStore).upgradeError.error === 'too-far')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_29, "Budova je příliš daleko"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_unref(buildingStore).upgradeError.message), 1))
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(buildingStore).pickUpError)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          (_unref(buildingStore).pickUpError.error === 'too-far')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_32, "Budova je příliš daleko"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(buildingStore).pickUpError.message), 1))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})