import { defineStore } from "pinia";
import {HeroService} from "../../common/api.service";
import type {Hero} from "../../type";

interface State {
    hireLoading: boolean;
    hireError: any;
    hero?: Hero;
}

export const useHeroStore = defineStore('hero', {
    state: () : State => ({
        hireLoading: false,
        hireError: null,
        hero: undefined,
    }),
    getters: {

    },
    actions: {
        async fetch() {
            this.hireLoading = true;
            this.hireError = null;
            try {
                this.hero = (await HeroService.get()).data;
            } catch (error) {
                console.log('Error fetch hero', error);
                this.hireError = error;
            }

            this.hireLoading = false;

            return this.hero;
        },

        async hire(data: {name?: string}) {
            this.hireLoading = true;
            this.hireError = null;
            try {
                this.hero = (await HeroService.hire(data)).data;
            } catch (error) {
                console.log('Error hire hero', error);
                this.hireError = error;
            }

            this.hireLoading = false;

            return this.hero;
        },
    },
})