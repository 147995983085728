import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "navbar navbar-expand-lg bg-light" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "navbar-brand" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "collapse navbar-collapse",
  id: "mainMenu"
}
const _hoisted_7 = {
  key: 0,
  class: "navbar-nav me-auto mb-2 mb-lg-0 d-flex"
}
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = {
  key: 1,
  class: "navbar-nav navbar-right"
}
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "nav-link text-black" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "container flex-grow-1 p-0 p-3" }

import {onMounted, watch} from "vue";
import router from "./router";
import * as Sentry from "@sentry/vue";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

import logo from '@/imgs/logo.webp';

import SignService from "./common/sign.service";

import { useSignStore } from "./store/sign";
import { usePlayerStore } from "./store/player";


export default /*@__PURE__*/_defineComponent({
  __name: 'Game',
  setup(__props) {


const signStore = useSignStore();
const playerStore = usePlayerStore();

watch(() => signStore.authenticated, (authenticated) => {
	if (authenticated === false) {
		router.push({name: 'sign'});
	}
});

watch(() => playerStore.player, (player) => {
	if (player) {
		Sentry.setUser(player);
	}
});

onMounted(() => {
	if (signStore.authenticated) {
		playerStore.fetchPlayer();
	} else {
		router.push({name: 'sign'});
	}

	if (playerStore.player) {
		Sentry.setUser(playerStore.player);
	}
});


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _unref(logo),
              alt: "Kokume",
              width: "32",
              height: "32",
              class: "d-inline-block"
            }, null, 8, _hoisted_5)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("button", {
            class: "navbar-toggler",
            type: "button",
            "data-bs-toggle": "collapse",
            "data-bs-target": "#mainMenu",
            "aria-controls": "mainMenu",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation"
          }, [
            _createElementVNode("span", { class: "navbar-toggler-icon" })
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            (_unref(signStore).authenticated)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                  _createElementVNode("li", _hoisted_8, [
                    _createVNode(_component_RouterLink, {
                      to: "/",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Domů")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_9, [
                    _createVNode(_component_RouterLink, {
                      to: "/dashboard",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Přehled")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_10, [
                    _createVNode(_component_RouterLink, {
                      to: "/map",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Mapa")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_11, [
                    _createVNode(_component_RouterLink, {
                      to: "/hero",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Hrdina")
                      ])),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_unref(signStore).authenticated)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                  _createElementVNode("li", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(playerStore).player?.nickname), 1)
                    ])
                  ]),
                  _createElementVNode("li", _hoisted_15, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "nav-link",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(SignService).signOut()), ["prevent"]))
                    }, [
                      _createVNode(_unref(FontAwesomeIcon), { icon: _unref(faSignOut) }, null, 8, ["icon"]),
                      _cache[5] || (_cache[5] = _createTextVNode(" Odhlásit se"))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("main", _hoisted_16, [
      _createVNode(_component_RouterView)
    ])
  ], 64))
}
}

})