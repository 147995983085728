import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelText as _vModelText, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group mb-2"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "form-group mb-2" }
const _hoisted_5 = { class: "list-unstyled" }
const _hoisted_6 = { class: "mb-2 text-black-50" }
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "list-unstyled" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_11 = { class: "mb-2" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 1,
  class: "list-unstyled sources-list"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 2,
  class: "text-warning"
}
const _hoisted_16 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_17 = ["disabled"]

import {computed, onMounted, ref, watch} from 'vue';
import Modal from './Modal.vue'
import { useBuildingStore } from "../store/building";
import { useMapStore } from "../store/map";
import { usePlayerStore } from "../store/player";
import Loading from "../components/Loading.vue";
import { LMap } from "@vue-leaflet/vue-leaflet";
import {Circle, LatLng, Marker} from "leaflet";

const buildingRadius = 15;


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildModal',
  props: {
    open: { type: Boolean },
    map: {}
  },
  setup(__props: any) {



// Must be the same as in the backend
const buildingPosition = ref<Marker>(undefined);
const usabilityRange = ref<Circle>(undefined);

const mapStore = useMapStore();
const buildingStore = useBuildingStore();
const playerStore = usePlayerStore();

const buildingType = ref<string>("");
const buildingName = ref<string>("");

const error = ref<string | null>(null);

async function build() {
	error.value = null;
	buildingStore.build(
		buildingStore.currentType,
		buildingName.value,
		buildingStore.currentPosition ?? playerStore.position.latLng,
		{}
	)
		.then(() => {
			mapStore.fetchData();
			resetForm();
			mapStore.state = 'none';
		})
		.catch((e) => {
			error.value = 'Nepodařilo se postavit budovu (' + buildingStore.buildingsError.message + ')';
		});
}

function preview() {
	mapStore.state = 'build_preview';
}

function selectPosition() {
	mapStore.state = 'build_position';
	buildingStore.currentPosition = playerStore.position.latLng;
}

function cancel() {
	// Do not cancel if we are in preview mode or selecting position
	if (mapStore.state === 'build_preview' || mapStore.state === 'build_position') {
		return;
	}

	buildingStore.resetCurrentType();
	buildingStore.resetCurrentPosition();
	buildingType.value = '';
	mapStore.state = 'none';
}

function resetForm() {
	buildingName.value = '';
}

function changeType() {
	buildingStore.changeCurrentType(buildingType.value);
	buildingStore.fetchResourcesInRange(buildingStore.currentType);
}

const canBuild = computed(() => {
	if (buildingStore.currentType === undefined) {
		return false;
	}

	if (buildingName.value === '') {
		return false;
	}

	for (const cost of buildingStore.currentType.cost) {
		if (playerStore.findResource(cost.type.slug).amount < cost.amount) {
			return false;
		}
	}

	return isPlaceEmpty.value;
});

const availableTypes = computed(() => {
	if (buildingStore.types === undefined) {
		return [];
	}

	return buildingStore.types.filter(type => {
		if (type.unique_in_world && buildingStore.buildings?.filter(b => b.type.slug === type.slug).length > 0) {
			return false;
		}

		if (type.unique_in_region && playerStore.current_region_buildings?.filter(b => b.type.slug === type.slug).length > 0) {
			return false;
		}

		return true;
	});
});

const isPlaceEmpty = computed(() => {
	let result = true;
	let buildingName = null;

	__props.map.leafletObject.eachLayer((layer) => {
		if (layer.feature?.properties.type !== 'building') {
			return;
		}

		const point = layer.getLatLng();
		const distance = playerStore.position.latLng.distanceTo(point);

		if (distance <= buildingRadius) {
			result = false;
			buildingName = layer.feature.properties.name;
		}
	});

	if (!result) {
		error.value = `V dosahu 15m je již budova ${buildingName}`;
	} else {
		error.value = null;
	}

	return result;
});

// show building range
watch(() => buildingStore.currentType, (newValue) => {
	if (newValue) {
		buildingName.value = newValue.name + ' - ' + playerStore.current_region?.name;

		if (!usabilityRange.value) {
			usabilityRange.value = new Circle(playerStore.position.latLng, {
				radius: buildingStore.currentType.range,
				color: '#000000',
				weight: 1.0,
				fillColor: '#000000',
				fillOpacity: 0.1,
			});

			usabilityRange.value.addTo(__props.map.leafletObject);
		}

		usabilityRange.value
			.setLatLng(playerStore.position.latLng)
			.setRadius(buildingStore.currentType.range);

	} else {
		if (usabilityRange.value) {
			usabilityRange.value.remove();
			usabilityRange.value = undefined;
		}
	}
});

// show building range
watch(() => buildingStore.currentPosition, (newValue) => {
	if (newValue && !buildingPosition.value) {
		buildingPosition.value = new Marker(playerStore.position.latLng, {
			draggable: true,
			title: 'Umístění budovy',
		});

		buildingPosition.value.addTo(__props.map.leafletObject);

		// Check if the market is not too far from the player, if so, move it to the edge of the circle
		buildingPosition.value.on('drag', (event) => {
			const center = playerStore.position.latLng;
			const markerLatLng = event.target.getLatLng();
			const radius = 50;

			const distance = __props.map.leafletObject.distance(center, markerLatLng);

			if (distance > radius) {
				const angle = Math.atan2(
					markerLatLng.lng - center.lng,
					markerLatLng.lat - center.lat
				);

				const newLat = center.lat + (radius * Math.cos(angle)) / 111320;
				const newLng = center.lng + (radius * Math.sin(angle)) / (111320 * Math.cos(center.lat * (Math.PI / 180)));

				buildingPosition.value.setLatLng([newLat, newLng]);
				buildingStore.currentPosition = new LatLng(newLat, newLng);
			}
		});

	} else if (!newValue && buildingPosition.value) {
		buildingPosition.value.remove();
		buildingPosition.value = undefined;
	}
});

onMounted(() => {
	error.value = null;
	buildingStore.fetchTypes();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    id: "build_modal",
    title: "Stavba",
    show: _ctx.open,
    onHide: cancel
  }, {
    body: _withCtx(() => [
      (_unref(buildingStore).types)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { for: "type" }, "Typ", -1)),
            _withDirectives(_createElementVNode("select", {
              id: "type",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((buildingType).value = $event)),
              onChange: changeType
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("option", {
                value: "",
                selected: "",
                disabled: ""
              }, "Vyber typ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableTypes.value, (type) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: type.slug
                }, _toDisplayString(type.name), 9, _hoisted_2))
              }), 256))
            ], 544), [
              [_vModelSelect, buildingType.value]
            ])
          ]))
        : (_openBlock(), _createBlock(Loading, { key: 1 })),
      (_unref(buildingStore).currentType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { for: "name" }, "Název", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((buildingName).value = $event)),
                id: "name",
                class: "form-control",
                type: "text"
              }, null, 512), [
                [_vModelText, buildingName.value]
              ])
            ]),
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, _toDisplayString(_unref(buildingStore).currentType.description), 1),
              _createElementVNode("li", _hoisted_7, "Čas stavby: " + _toDisplayString(_unref(buildingStore).currentType.build_time) + "h", 1),
              _createElementVNode("li", _hoisted_8, [
                _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Náklady:", -1)),
                _createElementVNode("ul", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).currentType.cost, (cost) => {
                    return (_openBlock(), _createElementBlock("li", null, [
                      _createTextVNode(_toDisplayString(cost.type.name) + ": " + _toDisplayString(cost.amount) + " ", 1),
                      (_unref(playerStore).findResource(cost.type.slug)?.amount < cost.amount)
                        ? (_openBlock(), _createElementBlock("strong", _hoisted_10, " (chybí " + _toDisplayString(cost.amount - (_unref(playerStore).findResource(cost.type.slug)?.amount ?? 0)) + " !!) ", 1))
                        : _createCommentVNode("", true)
                    ]))
                  }), 256))
                ])
              ]),
              _createElementVNode("li", _hoisted_11, [
                _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Produkce:", -1)),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).currentType.productions, ({type, levels}) => {
                    return (_openBlock(), _createElementBlock("li", null, [
                      _createTextVNode(_toDisplayString(type.name) + ": ", 1),
                      _createElementVNode("ol", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(levels, (amount) => {
                          return (_openBlock(), _createElementBlock("li", null, " lvl - " + _toDisplayString(amount) + " za hodinu ", 1))
                        }), 256))
                      ])
                    ]))
                  }), 256))
                ])
              ]),
              (_unref(buildingStore).currentType.productions)
                ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Zdroje v dosahu:", -1)),
                    (_unref(buildingStore).resourcesInRangeLoading)
                      ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                      : _createCommentVNode("", true),
                    (_unref(buildingStore).resourcesInRange?.length > 0)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).resourcesInRange, ({distance, amounts, resource_region}) => {
                            return (_openBlock(), _createElementBlock("li", null, [
                              _createTextVNode(_toDisplayString(resource_region.type.name) + " (" + _toDisplayString(distance) + "m) ", 1),
                              amounts
                                ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(amounts, ({type, amount}) => {
                                      return (_openBlock(), _createElementBlock("li", null, _toDisplayString(type.name) + ": " + _toDisplayString(amount), 1))
                                    }), 256))
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 256))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_15, "V dosahu nic využitelného"))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    "footer-before": _withCtx(() => [
      (error.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(error.value), 1))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-secondary",
        onClick: cancel
      }, "Zrušit"),
      (_unref(buildingStore).currentType)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-info",
            onClick: selectPosition
          }, "Doladit umístění"))
        : _createCommentVNode("", true),
      (_unref(buildingStore).currentType && _unref(buildingStore).currentType.range)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-info",
            onClick: preview
          }, "Ukázat dosah"))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: build,
        disabled: !canBuild.value
      }, "Postavit", 8, _hoisted_17)
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})