import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hero-page container mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4 text-center mb-4" }
const _hoisted_4 = { class: "hero-header" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "hero-name" }
const _hoisted_7 = {
  key: 0,
  class: "hero-description"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-4 col-md-12 mb-4" }
const _hoisted_10 = { class: "hero-equipment" }
const _hoisted_11 = { class: "col-lg-8 col-md-12" }
const _hoisted_12 = { class: "hero-inventory" }

import {computed, onMounted, ref} from "vue";
import {useInventoryStore} from "../store/inventory";
import {usePlayerStore} from "../store/player";

import heroImgMale from '@/imgs/hero_m.webp';
import heroImgFemale from '@/imgs/hero_f.webp';
import heroImgNone from '@/imgs/hero_n.webp';
import {playerSex} from "@/modules/game/type";
import PlayerInventory from "@/modules/game/components/inventory/PlayerInventory.vue";
import PlayerEquipment from "@/modules/game/components/inventory/PlayerEquipment.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeroView',
  setup(__props) {


const playerStore = usePlayerStore();
const inventoryStore = useInventoryStore();

const heroImg = computed(() => {
	if (!playerStore.player) {
		return undefined;
	}

	if (playerStore.player.sex === playerSex.MALE) {
		return heroImgMale;
	} else if (playerStore.player === playerSex.FEMALE) {
		return heroImgFemale;
	}

	return heroImgNone;
});

onMounted(() => {
	inventoryStore.fetchPlayerItems();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Hrdina", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: heroImg.value,
              alt: "Hero Portrait",
              class: "hero-portrait"
            }, null, 8, _hoisted_5),
            _createElementVNode("h1", _hoisted_6, _toDisplayString(_unref(playerStore).player?.nickname), 1),
            (_unref(playerStore).player?.description)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(playerStore).player?.description), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-8 mb-4" }, [
          _createElementVNode("div", { class: "hero-stats m-3 m-lg-0" }, [
            _createElementVNode("h3", null, "Statistiky"),
            _createElementVNode("div", { class: "stat" }, [
              _createElementVNode("span", null, "Zdraví"),
              _createElementVNode("div", { class: "progress" }, [
                _createElementVNode("div", {
                  class: "progress-bar bg-danger",
                  role: "progressbar",
                  style: {"width":"100%"}
                }, "100")
              ])
            ]),
            _createElementVNode("div", { class: "stat" }, [
              _createElementVNode("span", null, "Obratnost"),
              _createElementVNode("div", { class: "progress" }, [
                _createElementVNode("div", {
                  class: "progress-bar bg-success",
                  role: "progressbar",
                  style: {"width":"60%"}
                }, "30")
              ])
            ]),
            _createElementVNode("div", { class: "stat" }, [
              _createElementVNode("span", null, "Síla"),
              _createElementVNode("div", { class: "progress" }, [
                _createElementVNode("div", {
                  class: "progress-bar bg-primary",
                  role: "progressbar",
                  style: {"width":"90%"}
                }, "45")
              ])
            ])
          ])
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Vybavení", -1)),
            _createVNode(PlayerEquipment)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Inventář", -1)),
            _createVNode(PlayerInventory)
          ])
        ])
      ])
    ])
  ], 64))
}
}

})