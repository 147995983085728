import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Modal from "../Modal.vue";
import {useInventoryStore} from "../../store/inventory";
import {onMounted} from "vue";
import {useMapStore} from "../../store/map";
import PlayerInventory from "./PlayerInventory.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InventoryModal',
  setup(__props) {

const inventoryStore = useInventoryStore();
const mapStore = useMapStore();

function dropout(uuid: string) {
	console.log('dropout event', uuid);
	// inventoryStore.drop(uuid).then(() => {
	// 	mapStore.fetchData();
	// });
}

function move(from: number, to: number) {
	console.log('move event', from, to);
	// inventoryStore.move(from, to).then(() => {
	// 	mapStore.fetchData();
	// });
}

onMounted(() => {
	inventoryStore.fetchPlayerItems();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    id: "inventory",
    title: "Inventář",
    show: _unref(inventoryStore).open,
    onHide: _cache[0] || (_cache[0] = ($event: any) => (_unref(inventoryStore).open = false))
  }, {
    body: _withCtx(() => [
      _createVNode(PlayerInventory)
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})