import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inventory-grid" }

import Slot from "./Slot.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Inventory',
  props: {
	items: {
		type: Object,
		required: true,
	},
	maxItems: {
		type: Number,
		required: true,
	},
	slotKeyPrefix: {
		type: String,
		required: true,
	},
},
  emits: ["dragstart", "drop", "dropout", "move"],
  setup(__props, { emit: __emit }) {


const emit = __emit

const props = __props;

function moveItem(from: string, to: string) {
	props.items[to] = props.items[from];
	delete props.items[from];

	emit('move', from, to);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.maxItems, (slot, slotIndex) => {
      return (_openBlock(), _createBlock(Slot, {
        key: __props.slotKeyPrefix+slotIndex,
        "slot-key": __props.slotKeyPrefix+slotIndex,
        item: __props.items[__props.slotKeyPrefix+slotIndex],
        onMove: moveItem,
        onDropout: _cache[0] || (_cache[0] = (uuid) => emit('dropout', uuid)),
        onDragstart: _cache[1] || (_cache[1] = (event) => emit('dragstart', event)),
        onDrop: _cache[2] || (_cache[2] = (event) => emit('drop', event))
      }, null, 8, ["slot-key", "item"]))
    }), 128))
  ]))
}
}

})