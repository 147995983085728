import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-labelledby", "id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = {
  key: 0,
  class: "modal-body mt-2"
}
const _hoisted_8 = {
  key: 1,
  class: "modal-footer"
}

import { onMounted, ref, watch } from 'vue';
import { Modal } from 'bootstrap';


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
	id: {
		required: true,
		type: String,
	},
	show: {
		default: false,
		required: false,
		type: Boolean,
	},
	title: {
		default: '',
		required: false,
		type: String,
	},
},
  emits: ['hide', 'hidden', 'show', 'shown'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const bootstrapModal = ref<Modal|null>(null);
const modalEl = ref<HTMLDivElement|null>(null);

onMounted(() => {
	bootstrapModal.value = Modal.getOrCreateInstance(modalEl.value);

	modalEl.value.addEventListener('hide.bs.modal', () => emit('hide'));
	modalEl.value.addEventListener('hidden.bs.modal', () => emit('hidden'));
	modalEl.value.addEventListener('show.bs.modal', () => emit('show'));
	modalEl.value.addEventListener('shown.bs.modal', () => emit('shown'));
})

watch(() => props.show, (show) => {
	if (bootstrapModal.value) {
		show ? bootstrapModal.value.show() : bootstrapModal.value.hide();
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "aria-hidden": "true",
    class: "fade modal",
    ref_key: "modalEl",
    ref: modalEl,
    tabindex: "-1",
    "aria-labelledby": `${__props.id}-label`,
    id: __props.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createElementVNode("h1", {
              class: "modal-title fs-5",
              id: `${__props.id}-label`
            }, _toDisplayString(__props.title), 9, _hoisted_5),
            _cache[0] || (_cache[0] = _createElementVNode("button", {
              "aria-label": "Close",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              type: "button"
            }, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "body")
        ]),
        ('footer-before' in _ctx.$slots)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "footer-before")
            ]))
          : _createCommentVNode("", true),
        ('footer' in _ctx.$slots)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 8, _hoisted_1))
}
}

})