import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {useInventoryStore} from "../../store/inventory";
import Inventory from "./Inventory.vue";
import {onMounted} from "vue";
import Loading from "../Loading.vue";
import {useMapStore} from "../../store/map";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerInventory',
  setup(__props) {


const inventoryStore = useInventoryStore();
const mapStore = useMapStore();

function dropout(uuid: string) {
	inventoryStore.drop(uuid).then(() => {
		mapStore.fetchData();
	});
}

function move(from: string, to: string) {
	if (from === to) {
		return;
	}

	inventoryStore.move(from, to);
}

onMounted(() => {
	inventoryStore.fetchPlayerItems();
});


return (_ctx: any,_cache: any) => {
  return (_unref(inventoryStore).itemsLoading)
    ? (_openBlock(), _createBlock(Loading, { key: 0 }))
    : (_openBlock(), _createBlock(Inventory, {
        key: 1,
        items: _unref(inventoryStore).items,
        "max-items": 25,
        "slot-key-prefix": "i",
        onDropout: dropout,
        onMove: move
      }, null, 8, ["items"]))
}
}

})