import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode } from "vue"

import {onMounted} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


onMounted(() => {

});


return (_ctx: any,_cache: any) => {
  return _cache[0] || (_cache[0] = _createStaticVNode("<h1>Kokume!</h1><div class=\"p-4\"><p>Vítej ve hře Kokume! 🎉</p><p>Staň se vládcem světa a vydrancuj všechny zdroje!</p></div><div class=\"row\"><div class=\"col-md-6\"><div class=\"card mb-3\"><div class=\"card-header\"><h3 class=\"card-title\">Novinky</h3></div><div class=\"card-body\"><ul class=\"list-unstyled\"><li><h4>04. 01. 2025</h4><ul><li>Dodělán základ inventáře</li><li>Přidána stránka hrdiny</li></ul></li><li><h4>16. 11. 2024</h4><ul><li>Přidány novinky 🥳</li><li>Při stavění lze upravit poloha budovy</li><li>Detaily po rozkliknutí budovy v přehledu</li></ul></li><li><h4>9. 12. 2024</h4><ul><li>Přeměty</li><li>Základ inventáře</li></ul></li></ul></div></div></div><div class=\"col-md-6\"><div class=\"card mb-3\"><div class=\"card-header\"><h3 class=\"card-title\">Pracuje se na 🏗️</h3></div><div class=\"card-body\"><ul><li>Předměty, poklady, inventář</li><li>Hrdina</li><li>Útoky, armáda</li><li>Obchod</li></ul></div></div></div></div>", 3))
}
}

})