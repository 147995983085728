import { defineStore } from "pinia";
import { BuildingService } from "../../common/api.service";
import L, {LatLng} from "leaflet";

import type {ApiError, Building, BuildingType, Region, Resource, ResourceRegion} from "../../type";

import {usePlayerStore} from "../player";

export interface BuildingState {
    currentType?: BuildingType;
    currentPosition?: LatLng;

    types?: Array<BuildingType>;
    typesLoading: boolean;
    typesError: ApiError|null;

    buildings?: Array<Building>;
    buildingsLoading: boolean;
    buildingsError: ApiError|null;

    currentBuilding?: Building;
    currentBuildingLoading: boolean;
    currentBuildingError: null;

    resourcesInRange?: Array<{distance:number, amounts: Array<Resource>, resource_region: ResourceRegion}>;
    resourcesInRangeLoading: boolean;
    resourcesInRangeError: ApiError|null;

    pickUpLoading: boolean;
    pickUpError: ApiError|null;

    upgradeLoading: boolean;
    upgradeError: ApiError|null;
}

export const useBuildingStore = defineStore('building', {
    state: (): BuildingState => ({
        currentType: undefined,
        currentPosition: undefined,

        types: undefined,
        typesLoading: false,
        typesError: null,

        buildings: undefined,
        buildingsLoading: false,
        buildingsError: null,

        currentBuilding: undefined,
        currentBuildingLoading: false,
        currentBuildingError: null,

        resourcesInRange: undefined,
        resourcesInRangeLoading: false,
        resourcesInRangeError: null,

        pickUpLoading: false,
        pickUpError: null,

        upgradeLoading: false,
        upgradeError: null,
    }),
    getters: {
        findBuilding(state: BuildingState) {
            return (uuid: string) => state.buildings && state.buildings.find((building) => building.uuid === uuid);
        },

        findType(state: BuildingState) {
            return (slug: string) =>  state.types && state.types.find((type) => type.slug === slug);
        },

        canUpgradeCurrentBuilding(state: BuildingState) {
            if (!state.currentBuilding) {
                return false;
            }

            if (state.currentBuilding.level >= state.currentBuilding.type.max_level) {
                return false;
            }

            const playerStore = usePlayerStore();

            for (const cost of state.currentBuilding.upgrade_cost) {
                if (playerStore.findResource(cost.type.slug).amount < cost.amount) {
                    return false;
                }
            }

            return true;
        },

        regionsBuildings(state: BuildingState): {[key: string]: {region: Region, buildings: Building[]}} {
            if (!state.buildings) {
                return {};
            }

            const regionsBuildings : {[key: string]: {region: Region, buildings: Building[]}} = {};
            for (const building of state.buildings) {
                const regionId = building.region.id.toString();
                if (!regionsBuildings[regionId]) {
                    regionsBuildings[regionId] = {
                        region: building.region,
                        buildings: [],
                    };
                }

                regionsBuildings[regionId].buildings.push(building);
            }

            return regionsBuildings;
        }
    },
    actions: {
        async fetchTypes() {
            this.types = undefined;

            this.typesLoading = true;
            this.typesError = null;

            await BuildingService.getBuildingTypes()
                .then((response) => {
                    this.types = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.typesError = error;
                })
                .finally(() => {
                    this.typesLoading = false;
                });
        },

        async fetchBuildings() {
            this.buildingsLoading = true;
            this.buildingsError = null;
            this.buildings = undefined;

            await BuildingService.getBuildings()
                .then((response) => {
                    this.buildings = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.buildingsError = true;
                })
                .finally(() => {
                    this.buildingsLoading = false;
                });
        },

        async fetchResourcesInRange(buildingType: BuildingType) {
            this.resourcesInRangeLoading = true;
            this.resourcesInRangeError = null;
            this.resourcesInRange = undefined;

            let resourcesInRange = null;
            try {
                resourcesInRange = (await BuildingService.getResourcesInRange(buildingType.slug)).data;
            } catch (error) {
                console.log('Error fetching resources in range', error);
                this.resourcesInRangeError = error;
            }

            this.resourcesInRange = resourcesInRange;
            this.resourcesInRangeLoading = false;

            return resourcesInRange;
        },

        async build(type: BuildingType, name: string, position: L.LatLng, options: Object = {}) {
            this.buildingsLoading = true;
            this.buildingsError = null;

            let newBuilding = null;
            try {
                newBuilding = (await BuildingService.addBuilding(type.slug, name, position, options)).data;
            } catch (error) {
                console.log('Error adding building', error);
                this.buildingsError = error;
                throw error;
            }

            this.buildingsLoading = false;

            this.buildings.push(newBuilding);

            return newBuilding;
        },

        async pickUpResources(building: Building) {
            this.pickUpLoading = true;
            this.pickUpError = null;

            let updatedBuilding = null;
            try {
                updatedBuilding = (await BuildingService.pickUpResources(building.uuid)).data;

                // Update the building in the store
                this.buildings = this.buildings.map((b: Building) => b.uuid === updatedBuilding.id ? updatedBuilding : b);

            } catch (error) {
                console.log('Error picking up resources', error);
                this.pickUpError = error;
            }

            this.pickUpLoading = false;

            return updatedBuilding;
        },

        async upgradeBuilding(building: Building) {
            this.upgradeLoading = true;
            this.upgradeError = null;

            let updatedBuilding = null;
            try {
                updatedBuilding = (await BuildingService.upgradeBuilding(building.uuid)).data;

                // Update the building in the store
                this.buildings = this.buildings.map((b: Building) => b.uuid === updatedBuilding.id ? updatedBuilding : b);

            } catch (error) {
                console.log('Error picking up resources', error);
                this.upgradeError = error;
            }

            this.upgradeLoading = false;

            return updatedBuilding;
        },

        changeCurrentType(type: BuildingType|string) {
            if (typeof type === 'string') {
                type = this.findType(type);
            }
            this.currentType = type;
        },

        changeCurrentBuilding(uuid: string) {
            this.currentBuilding = this.findBuilding(uuid);

            if (!this.currentBuilding) {
                this.currentBuildingLoading = true;
                this.currentBuildingError = null;

                BuildingService.getBuilding(uuid)
                    .then((response) => {
                        this.currentBuilding = response && response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.currentBuildingError = true;
                    })
                    .finally(() => {
                        this.currentBuildingLoading = false;
                    });
            }
        },

        resetCurrentType() {
            this.currentType = undefined;
        },

        resetCurrentPosition() {
            this.currentPosition = undefined;
        },

        resetCurrentBuilding() {
            this.currentBuilding = undefined;
        },

    },
})