import {createApp} from "vue";
import Game from "./Game.vue";

import router from './router'
import { createPinia } from 'pinia'
import ApiService from "./common/api.service";

import * as Sentry from "@sentry/vue";
import Hotjar from '@hotjar/browser';

import {enableDragDropTouch} from '../../lib/dragdroptouch';

const pinia = createPinia()
const app = createApp(Game);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        dsn: "https://a5854783231db749c1f874e23eca8a91@o4508092856008704.ingest.de.sentry.io/4508092867870800",
        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration(),
        ],

        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions

        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/kokume\.eu/],

        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    Hotjar.init(5169243, 6);
}

app.use(router)
    .use(pinia);

ApiService.init();

app.mount('#v_game')
