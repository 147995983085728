import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 7 }
const _hoisted_9 = { key: 8 }
const _hoisted_10 = { key: 9 }
const _hoisted_11 = { key: 10 }
const _hoisted_12 = { key: 11 }
const _hoisted_13 = { key: 12 }

import {computed, onBeforeUnmount, ref, watch} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Timer',
  props: {
	endDate: {
		type: [Date],
		required: true
	},
	negative: {
		type: Boolean,
		default: false
	}
},
  emits: ['endTime'],
  setup(__props, { emit: __emit }) {


const props = __props;

const emit = __emit;

const now = ref(new Date());
const timer = ref<NodeJS.Timeout>(undefined);

const day = computed(() => {
	return Math.trunc((props.endDate.getTime() - now.value.getTime()) / 1000 / 3600 / 24);
});

const hour = computed(() => {
	return Math.trunc((props.endDate.getTime() - now.value.getTime()) / 1000 / 3600);
});

const min = computed(() => {
	return Math.trunc((props.endDate.getTime() - now.value.getTime()) / 1000 / 60) % 60;
});

const sec = computed(() => {
	return Math.trunc((props.endDate.getTime() - now.value.getTime()) / 1000) % 60;
});

const millis = computed(() => {
	return Math.trunc((props.endDate.getTime() - now.value.getTime()));
});

watch(() => props.endDate, (newVal) => {
	if (timer.value) {
		clearInterval(timer.value);
	}
	timer.value = setInterval(() => {
		now.value = new Date();
		if (props.negative) {
			return;
		}
		if (now.value > newVal) {
			now.value = newVal;
			emit('endTime');
			clearInterval(timer.value);
		}
	}, 1000);
}, {immediate: true});

onBeforeUnmount(() => {
	clearInterval(timer.value);
});


return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", {
    day: day.value,
    hour: hour.value,
    min: min.value,
    sec: sec.value
  }, () => [
    (millis.value > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "zbývá "))
      : _createCommentVNode("", true),
    (millis.value > 0 && day.value > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(day.value) + " " + _toDisplayString(day.value >= 5 ? 'dní' : (day.value >= 2 ? 'dny' : 'den')) + "  ", 1))
      : _createCommentVNode("", true),
    (millis.value > 0 && hour.value > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(hour.value) + " " + _toDisplayString(hour.value >= 5 ? 'hodin' : (hour.value >= 2 ? 'hodiny' : 'hodina')) + "  ", 1))
      : _createCommentVNode("", true),
    (millis.value > 0 && min.value > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(min.value) + " " + _toDisplayString(min.value >= 5 ? 'minut' : (min.value >= 2 ? 'minuty' : 'minuta')), 1))
      : _createCommentVNode("", true),
    (millis.value > 0 && !day.value && !hour.value && !min.value && sec.value >= 20)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, "minutka"))
      : _createCommentVNode("", true),
    (millis.value > 0 && !day.value && !hour.value && !min.value && sec.value < 20)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "chvilinka"))
      : _createCommentVNode("", true),
    (millis.value === 0 || (millis.value < 0 && !__props.negative))
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "hotovo"))
      : _createCommentVNode("", true),
    (__props.negative && millis.value < 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_8, "před "))
      : _createCommentVNode("", true),
    (__props.negative && day.value < 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(-day.value) + " " + _toDisplayString(day.value <= -5 ? 'dny' : 'dnem') + "  ", 1))
      : _createCommentVNode("", true),
    (__props.negative && hour.value < 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(-hour.value) + " " + _toDisplayString(hour.value <= -5 ? 'hodinami' : 'hodinou') + "  ", 1))
      : _createCommentVNode("", true),
    (__props.negative && min.value < 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(-min.value) + " " + _toDisplayString(min.value <= -5 ? 'minutami' : 'minutou'), 1))
      : _createCommentVNode("", true),
    (__props.negative && millis.value < 0 && !day.value && !hour.value && !min.value && sec.value <= -20)
      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "minutka"))
      : _createCommentVNode("", true),
    (__props.negative && millis.value < 0 && !day.value && !hour.value && !min.value && sec.value < -20)
      ? (_openBlock(), _createElementBlock("span", _hoisted_13, "chvilinka"))
      : _createCommentVNode("", true)
  ])
}
}

})