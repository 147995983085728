import {defineStore} from "pinia";
import {MapDataService, RegionService} from "../../common/api.service";
import type {Region} from "../../type";

export interface RegionState {
    region?: Region,
    regionLoading: boolean,
    regionError: any,

    playerRegions?: Region[],
    playerLoading: boolean,
    playerError: any,

    occupyLoading: boolean,
    occupyError: any,
}

export const useRegionStore = defineStore('region', {
    state: () : RegionState => ({
        region: undefined,
        regionError: null,
        regionLoading: false,

        playerRegions: undefined,
        playerError: null,
        playerLoading: false,

        occupyLoading: false,
        occupyError: null,
    }),
    getters: {

    },
    actions: {
        async fetchRegion(id: number) {
            if (this.region?.id === id) {
                return;
            }

            this.region = undefined;
            this.regionLoading = true;

            await RegionService.getRegion(id)
                .then((response) => {
                    this.region = response && response.data;
                })
                .catch((error) => {
                    this.regionError = error;
                }).finally(() => {
                    this.regionLoading = false;
                });
        },

        async occupyCurrentRegion() {
            this.occupyLoading = true;
            this.occupyError = null;

            await RegionService.occupyCurrentRegion()
                .then((response) => {
                    this.region = response && response.data;
                })
                .catch((error) => {
                    this.occupyError = error;
                }).finally(() => {
                    this.occupyLoading = false;
                });
        },

        async fetchPlayerRegions() {
            this.playerRegions = undefined;
            this.playerLoading = true;

            await RegionService.getPlayerRegions()
                .then((response) => {
                    this.playerRegions = response && response.data;
                })
                .catch((error) => {
                    this.playerError = error;
                }).finally(() => {
                this.playerLoading = false;
            });
        },
    },
})