import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode } from "vue"

import {useInventoryStore} from "../../store/inventory";
import {onMounted, ref} from "vue";
import {useMapStore} from "../../store/map";
import Slot from "@/modules/game/components/inventory/Slot.vue";
import Inventory from "@/modules/game/components/inventory/Inventory.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerEquipment',
  emits: ["dragstart", "drop", "dropout", "move"],
  setup(__props, { emit: __emit }) {

const inventoryStore = useInventoryStore();
const mapStore = useMapStore();

const emit = __emit;

const slots = {
	weapon: {
		title: 'Zbraň',
		key: 'weapon',
		allowed_item_types: ['weapon'],
	},
	ring1: {
		title: 'Prsten 1',
		key: 'ring1',
		allowed_item_types: ['ring'],
	},
	ring2: {
		title: 'Prsten 2',
		key: 'ring2',
		allowed_item_types: ['ring'],
	},
	head: {
		title: 'Hlava',
		key: 'head',
		allowed_item_types: ['hat'],
	},
	necklace: {
		title: 'Náhrdelník',
		key: 'necklace',
		allowed_item_types: ['necklace'],
	},
	chest: {
		title: 'Hrudník',
		key: 'chest',
		allowed_item_types: ['armor'],
	},
	legs: {
		title: 'Nohy',
		key: 'legs',
		allowed_item_types: ['trousers'],
	},
	feet: {
		title: 'Chodidla',
		key: 'feet',
		allowed_item_types: ['boots'],
	},
};

const grid = ref<HTMLDivElement>();

function dropout(uuid: string) {
	inventoryStore.drop(uuid).then(() => {
		mapStore.fetchData();
	});
}

function move(from: string, to: string) {
	if (from === to) {
		return;
	}

	const item = inventoryStore.items[from] || inventoryStore.equipment[from];
	if (!item || !slots[to].allowed_item_types.includes(item.type)) {
		const slotEl = grid.value?.querySelector(`[data-slot="${to}"]`);
		if (slotEl) {
			slotEl.style.border = '3px solid red';
			setTimeout(() => slotEl.style.border = '', 50);
		}
		return;
	}

	inventoryStore.move(from, to);
}

onMounted(() => {
	inventoryStore.fetchItems();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "grid",
    ref: grid,
    class: "equipment-grid"
  }, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(slots, (slot) => {
      return _createVNode(Slot, {
        "slot-key": slot.key,
        "slot-title": slot.title,
        item: _unref(inventoryStore).equipment[slot.key],
        onDropout: dropout,
        onMove: move
      }, null, 8, ["slot-key", "slot-title", "item"])
    }), 64))
  ], 512))
}
}

})