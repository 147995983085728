import axios, {AxiosResponse} from "axios";
import JwtService from "./jwt.service";

import L from "leaflet";
import { GeoJsonObject } from "geojson";

import { useSignStore } from "../store/sign";

const ApiService = {
    init() {
        axios.defaults.baseURL = "/api/v1";

        if (JwtService.getAccessToken()) {
            this.setAuthorization();
            useSignStore().authenticated = true;
        }

        let refreshingToken = false;
        axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response.status === 401 && JwtService.hasAccessToken()) {

                    // If the request was for a new token, and it failed, log the user out
                    if (error.config.url === "/oauth2/access_token") {

                        await OauthService.clearToken().then(() => {
                            useSignStore().authenticated = false;
                        });

                        return Promise.reject({
                            error: "unauthorized",
                            message: "Your session has expired. Please log in again.",
                            context: {
                                originalError: error,
                            }
                        });
                    }

                    // If we're already refreshing the token, wait for it to finish
                    if (refreshingToken) {
                        return new Promise((resolve, reject)=> {
                            const interval = setInterval(() => {
                                if (!refreshingToken) {
                                    clearInterval(interval);
                                    resolve(true);
                                }
                            }, 100);
                        }).then(() => {
                            // Retry the original request with the new token
                            error.config.headers["Authorization"] = `Bearer ${JwtService.getAccessToken()}`;

                            return axios.request(error.config);
                        });
                    }

                    // Refresh the token
                    refreshingToken = true;
                    return OauthService.refreshToken()
                        .then(() => {
                            console.log("Retrying request after token refresh");

                            // Retry the original request with the new token
                            error.config.headers["Authorization"] = `Bearer ${JwtService.getAccessToken()}`;

                            return axios.request(error.config);
                        }).finally(() => {
                            refreshingToken = false;
                        });
                }

                if (error.response.status >= 500) {
                    return Promise.reject({
                        error: error.response.data.error || "server_error",
                        message: error.response.data.message || "Application encountered an internal error. Please try again later.",
                        context: error.response.data.context || {},
                    });
                }

                return Promise.reject({
                    error: error.response.data.error || "unknown_error",
                    message: error.response.data.message || "An unknown error occurred. Please try again later, or contact support.",
                    context: error.response.data.context || {},
                });
            }
        );
    },

    setAuthorization() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getAccessToken()}`;
    },

    destroyAuthorization() {
        axios.defaults.headers.common["Authorization"] = "";
    },

    async get(resource: string, params: Object = undefined) {
        return axios.get(`${resource}`, {params});
    },

    async post(resource: string, data: Object = undefined) {
        return axios.post(`${resource}`, data);
    },

    async put(resource: string, data: Object = undefined) {
        return axios.put(`${resource}`, data);
    },

    async delete(resource: string) {
        return axios.delete(resource);
    }
};

export default ApiService;

export const MapDataService = {
    getGeoJson(bounds: L.LatLngBounds) : Promise<AxiosResponse<{data: GeoJsonObject}>> {
        return ApiService.get("/map/data", { bbox: bounds.toBBoxString() });
    },
};

export const RegionService = {
    getRegion(id: number) {
        return ApiService.get(`/region/${id}`);
    },

    getResource(id: number) {
        return ApiService.get(`/resource/${id}`);
    },

    occupyCurrentRegion() {
        return ApiService.post(`/occupy-region`);
    },

    getPlayerRegions() {
        return ApiService.get(`/player-regions`);
    },
};

export const OauthService = {
    client_id: "web_app",
    client_secret: "",

    async getToken(username: string, password: string) {
        return axios.post(
            `/oauth2/access_token`, {
                grant_type: "password",
                client_id: this.client_id,
                client_secret: this.client_secret,
                username,
                password,
            }, {
                baseURL: '/api',
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        ).then(response => {
            JwtService.saveToken(response.data);
            ApiService.setAuthorization();
        });
    },

    async refreshToken() {
        if (!JwtService.getRefreshToken()) {
            throw new Error("Refresh token not found");
        }

        return axios.post(
            `/oauth2/access_token`, {
                grant_type: "refresh_token",
                refresh_token: JwtService.getRefreshToken(),
                client_id: this.client_id,
                client_secret: this.client_secret,
            }, {
                baseURL: '/api',
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        ).then(response => {
            console.log("Refreshed token");
            JwtService.saveToken(response.data);
            ApiService.setAuthorization();
        }).catch(() => {
            console.log("Failed to refresh token, logging out");
            JwtService.clearToken();
            ApiService.destroyAuthorization();
        });
    },

    async clearToken() {
        JwtService.clearToken();
        ApiService.destroyAuthorization();
    }
};

export const PlayerService = {
    get() {
        return ApiService.get("/player");
    },

    updatePlayer(positionData: {latLng: L.LatLng, accuracy: number, speed: number}) {
        return ApiService.post(`/player`, {
            latLng: {lat: positionData.latLng.lat, lng: positionData.latLng.lng},
            accuracy: positionData.accuracy,
            speed: positionData.speed,
        });
    },

    register(email: string, nickname: string, password: string, game_password: string) {
        return ApiService.put(`/player-registration`, {email, nickname, password, game_password});
    },
};

export const PlayerItemService = {
    getItems() {
        return ApiService.get(`/player/item`);
    },

    getItem(uuid: string) {
        return ApiService.get(`/player/item/${uuid}`);
    },

    pickUp(uuid: string) {
        return ApiService.post(`/player/item/${uuid}/pick-up`);
    },

    drop(uuid: string) {
        return ApiService.post(`/player/item/${uuid}/drop`);
    },

    move(from: string, to: string) {
        return ApiService.post(`/player/item/move?from=${from}&to=${to}`);
    },
};

export const BuildingService = {
    getBuildings() {
        return ApiService.get(`/building/list`);
    },

    getBuilding(uuid: string) {
        return ApiService.get(`/building/${uuid}`);
    },

    addBuilding(type: string, name: string, position: L.LatLng, options: Object = {}) {
        return ApiService.put(`/building`, {
            type,
            name,
            position,
            options,
        });
    },

    getBuildingTypes() {
        return ApiService.get(`/building-types`);
    },

    getResourcesInRange(buildingType: string) {
        return ApiService.get(`/building-type/${buildingType}/resources-in-range`);
    },

    pickUpResources(buildingId: string) {
        return ApiService.get(`/building/${buildingId}/pick-up-resources`);
    },

    upgradeBuilding(buildingId: string) {
        return ApiService.get(`/building/${buildingId}/upgrade`);
    }
};