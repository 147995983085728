import { defineStore } from "pinia";
import {PlayerItemService} from "../../common/api.service";
import {ApiError, Item} from "../../type";

interface State {
    open: boolean;

    items: { [key: string]: Item };
    equipment: { [key: string]: Item };
    itemsLoading: boolean;
    itemsError: ApiError|null;
    size: number;

    current: Item | null;
    currentLoading: boolean;
    currentError: ApiError|null;
}

export const useInventoryStore = defineStore('inventory', {
    state: () : State => ({
        open: false,

        items: {},
        equipment: {},
        itemsLoading: false,
        itemsError: null,
        size: 25,

        current: null,
        currentLoading: false,
        currentError: null,
    }),
    getters: {},
    actions: {
        async get(id: string) {
            this.currentLoading = true;
            this.currentError = null;

            PlayerItemService.getItem(id)
                .then((response) => {
                    this.current = response.data;
                })
                .catch((error) => {
                    this.currentError = error;
                });

            this.currentLoading = false;
        },

        async fetchPlayerItems() {
            this.itemsLoading = true;
            this.itemsError = null;

            return PlayerItemService.getItems()
                .then((response) => {
                    this.items = response.data.items;
                    this.equipment = response.data.equipment;
                })
                .catch((error) => {
                    this.itemsError = error;
                })
                .finally(() => {
                    this.itemsLoading = false;
                });
        },

        async pickUp(id: string) {
            return PlayerItemService.pickUp(id)
                .then(() => {
                    this.fetchPlayerItems();
                });
        },

        async drop(id: string) {
            return PlayerItemService.drop(id)
                .then(() => {
                    this.fetchPlayerItems();
                });
        },

        async move(from: string, to: string) {
            return PlayerItemService.move(from, to)
                .then((response) => {
                    this.items = response.data.items;
                    this.equipment = response.data.equipment;
                });
        },
    },
})