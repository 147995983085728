import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["data-slot"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["data-durability", "data-bs-title", "data-bs-content", "data-bs-target"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "item-name" }
const _hoisted_6 = { class: "item-durability-bar" }
const _hoisted_7 = { class: "durability" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "text-black-50" }
const _hoisted_10 = { class: "list-unstyled" }
const _hoisted_11 = { key: 0 }

import unknownItemImg from '@/imgs/items/unknown.webp';
import { Popover } from 'bootstrap';
import {onUpdated, ref, watch} from "vue";
import {Item} from "@/modules/game/type";


export default /*@__PURE__*/_defineComponent({
  __name: 'Slot',
  props: {
	slotKey: {
		type: String,
		required: true,
	},
	slotTitle: {
		type: String,
		required: false,
	},
	item: {
		type: Object as Item,
		required: false,
	},
},
  emits: ["dragstart", "drop", "dropout", "move"],
  setup(__props, { emit: __emit }) {


const emit = __emit

const props = __props;

const itemNode = ref<HTMLDivElement | null>(null);

function startDrag(evt: DragEvent) {
	evt.dataTransfer.dropEffect = 'move'
	evt.dataTransfer.effectAllowed = 'move'
	evt.dataTransfer.setData('slotKey', props.slotKey)

	emit('dragstart', evt);
}

function onDrop(evt: DragEvent) {
	const slotKey= evt.dataTransfer.getData('slotKey')
	if (slotKey) {
		emit('move', slotKey, props.slotKey);
	}

	emit('drop', evt);
}

// Initialize item popovers
onUpdated(() => {
	const popover = itemNode.value.querySelector('[data-bs-toggle="popover"]');
	if (popover) {
		Popover.getOrCreateInstance(popover, {
			trigger: 'click',
			placement: 'top',
			html: true,
			content: document.getElementById(popover.getAttribute('data-bs-target')).innerHTML,
		});
	}
});

document.addEventListener('click', (event) => {
	if (!itemNode.value) {
		return;
	}

	const popover = itemNode.value.querySelector('[data-bs-toggle="popover"]');
	if (popover) {
		const instance = Popover.getInstance(popover);
		if (instance && instance._config.trigger === 'click') {
			instance.hide();
		}
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "itemNode",
    ref: itemNode,
    class: "inventory-slot",
    "data-slot": __props.slotKey,
    onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
    onDrop: _cache[4] || (_cache[4] = ($event: any) => (onDrop($event)))
  }, [
    (!__props.item && __props.slotTitle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.slotTitle), 1))
      : _createCommentVNode("", true),
    (__props.item)
      ? (_openBlock(), _createElementBlock("div", {
          key: __props.item.uuid,
          draggable: "true",
          onDragstart: _cache[1] || (_cache[1] = ($event: any) => (startDrag($event))),
          class: "inventory-item",
          "data-durability": __props.item.durability,
          "data-bs-toggle": "popover",
          "data-bs-title": __props.item.name,
          "data-bs-content": __props.item.description,
          "data-bs-target": 'item_popover_' + __props.item.uuid
        }, [
          _createElementVNode("button", {
            class: "btn btn-sm discard-btn",
            title: "Zahodit",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('dropout', __props.item.uuid)))
          }, "✖"),
          _createElementVNode("img", {
            src: _unref(unknownItemImg),
            alt: "Item Image",
            class: "item-image"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, _toDisplayString(__props.item.name), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(__props.item.durability) + "%", 1)
          ]),
          _createElementVNode("div", {
            id: 'item_popover_' + __props.item.uuid,
            class: "d-none"
          }, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(__props.item.category), 1),
            _createElementVNode("p", null, _toDisplayString(__props.item.description), 1),
            _cache[5] || (_cache[5] = _createElementVNode("h5", null, "Statistiky", -1)),
            _createElementVNode("ul", _hoisted_10, [
              (__props.item.durability)
                ? (_openBlock(), _createElementBlock("li", _hoisted_11, "Životnost: " + _toDisplayString(__props.item.durability), 1))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_8)
        ], 40, _hoisted_3))
      : _createCommentVNode("", true)
  ], 40, _hoisted_1))
}
}

})