import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading d-inline" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", {
      class: "spinner-border spinner-border-sm text-primary",
      role: "status"
    }, [
      _createElementVNode("span", { class: "visually-hidden" }, "Načítání...")
    ], -1)
  ])))
}