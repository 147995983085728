import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "equipment-grid" }

import {useInventoryStore} from "../../store/inventory";
import {onMounted} from "vue";
import {useMapStore} from "../../store/map";
import Slot from "@/modules/game/components/inventory/Slot.vue";
import Inventory from "@/modules/game/components/inventory/Inventory.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerEquipment',
  emits: ["dragstart", "drop", "dropout", "move"],
  setup(__props, { emit: __emit }) {

const inventoryStore = useInventoryStore();
const mapStore = useMapStore();

const emit = __emit;

const slots = {
	weapon: {
		title: 'Zbraň',
		key: 'weapon',
	},
	armor: {
		title: 'Brnění',
		key: 'armor',
	},
	helmet: {
		title: 'Přilba',
		key: 'helmet',
	},
};

function dropout(uuid: string) {
	inventoryStore.drop(uuid).then(() => {
		mapStore.fetchData();
	});
}

function move(from: string, to: string) {
	if (from === to) {
		return;
	}

	inventoryStore.move(from, to);
}

onMounted(() => {
	inventoryStore.fetchPlayerItems();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(slots, (slot) => {
      return _createVNode(Slot, {
        "slot-key": slot.key,
        "slot-title": slot.title,
        item: _unref(inventoryStore).equipment[slot.key],
        onDropout: dropout,
        onMove: move
      }, null, 8, ["slot-key", "slot-title", "item"])
    }), 64))
  ]))
}
}

})